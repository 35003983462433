var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingDetails)?_c('div',{staticClass:"loader"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_c('div',{staticClass:"px-2 pl-sm-6 pl-md-8 py-2"},[_c('div',[_c('p',{staticClass:"drawer_title"},[_vm._v("Form Entry")]),_c('p',{staticClass:"drawer_info mb-0"},[_vm._v("Title")]),_c('p',{staticClass:"form__title"},[_vm._v(" "+_vm._s(_vm.entryDetails ? _vm.entryDetails.data.form_title : "")+" ")]),(_vm.status)?_c('div',{staticClass:"section__div mb-3"},[_c('p',{staticClass:"headerText mb-0"},[_vm._v("Status")]),_c('v-chip',{staticClass:" d-inline-flex justify-center align-center ml-n2 px-3 font-weight-bold text-body-2 text-capitalize mt-0",attrs:{"small":"","color":_vm.chipColor}},[_vm._v(" "+_vm._s(_vm.status)+" "),_c('img',{staticClass:"ml-4",attrs:{"src":_vm.chipIcon,"alt":""}})])],1):_vm._e(),_c('div',{staticClass:"section__div mb-3"},[_c('p',{staticClass:"headerText mb-0"},[_vm._v("Entry ID")]),_c('p',{staticClass:"valueText mb-0"},[_vm._v(_vm._s(_vm.entryDetails ? _vm.entryDetails.id : ""))])]),_c('div',[_c('div',{staticClass:"section__div"},[_c('p',{staticClass:"headerText mb-0"},[_vm._v("Entry")]),_c('div',{staticClass:"fixed-container",style:(_vm.approvals.length > 0 ? _vm.containerHeight : _vm.adjustedHeight)},[_vm._l((_vm.filteredFormEntries),function(item,i){return _c('v-row',{key:i,staticClass:"entryItemContainer"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"entry__title text-truncate ",staticStyle:{"max-width":"400px"}},'p',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1),_c('v-col',{attrs:{"cols":"8"}},[(typeof item.value === 'object' && item.value.hasOwnProperty('file'))?_c('a',{staticClass:"linkText",attrs:{"href":item.value.file,"target":"_blank","download":item.value.name}},[_vm._v(" "+_vm._s(item.value.name)+" ")]):_c('p',{staticClass:"entry__value"},[_vm._v(" "+_vm._s(item.value)+" ")])])],1)}),(_vm.entryDetails && _vm.entryDetails.data.attachment)?[_c('div',_vm._l((_vm.entryDetails.data.attachment),function(item,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"entry__title mb-1"},[_vm._v(_vm._s(item.attachment_name))])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"linkText text-truncate",attrs:{"href":item.url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.url)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.url))])])],1)],1)}),1)]:_vm._e(),_vm._l((_vm.filteredFormEntriesForArraysOnly),function(item,i){return _c('div',{key:i,staticClass:" mb-3"},[_c('v-row',{staticClass:"paymentData rounded d-flex flex-column pa-4 mt-2"},[_c('v-col',{staticClass:"pa-0 mb-2"},[_c('p',{staticClass:"entry__title px-3"},[_vm._v(_vm._s(item.name))])]),_vm._l((item.value),function(data,j){return _c('v-col',{key:j,staticClass:"ma-2 pa-2 cardLoop rounded-4"},[_vm._l((data),function(value,key){return [(key === 'name' || key === 'amount' || key === 'pay')?_c('v-row',{key:key,staticClass:"mx-3 d-flex align-center entryItemContainer"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"entry__title text-truncate"},'p',attrs,false),on),[_vm._v(" "+_vm._s(key)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(key))])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"entry__value text-truncate",staticStyle:{"max-width":"400px","color":"#465263"}},'p',attrs,false),on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])],1)],1):_vm._e()]})],2)})],2)],1)})],2)]),_c('div',{staticClass:"approval-container"},[_c('div',{staticClass:"comment"},[[(_vm.approvals && _vm.approvals.length > 0)?_c('div',{staticClass:"view-comments"},[_c('img',{attrs:{"src":require('@/assets/approval-approved.svg'),"alt":""}}),_c('button',{on:{"click":function($event){_vm.showApprovals = !_vm.showApprovals}}},[_vm._v(" "+_vm._s(_vm.showApprovals ? "hide" : "view")+" approvals ")])]):_vm._e(),_c('div',{staticClass:"titleh",class:{ show: _vm.showApprovals }},[_vm._v(" Below is a timeline of approvals for this form entry ")]),(_vm.approvals && _vm.approvals.length > 0)?_c('div',{staticClass:"timeline",class:{ show: _vm.showApprovals }},_vm._l((_vm.approvals),function(approval,index){return _c('span',{key:index},[_c('div',{staticClass:"timeline__data"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"approver"},[_vm._v(_vm._s(approval.approver.username))]),_c('span',{staticClass:"status"},[_vm._v(_vm._s(approval.is_approved ? "Approved" : "Denied")+" "),_c('img',{attrs:{"src":require(("@/assets/approval-" + (approval.is_approved ? 'approved' : 'denied') + ".svg"))}})])]),_c('div',{staticClass:"quote"},[_vm._v(" "+_vm._s(approval.comment)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("date")(approval.created_at,'full')))])]),(index !== _vm.approvals.length - 1)?_c('div',{staticClass:"timeline__divider"}):_vm._e()])}),0):_vm._e()]],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }