import Auth from "../../api/auth";

const state = {
  user: null,
  singleUser: null,
  isLoggedIn: localStorage.getItem("userId") != null,
  itoken: sessionStorage.getItem("itoken"),
  teamMembers: null,
  settingsData: null,
};

const getters = {
  isLoggedIn: (state) => !!state.user,
  user: (state) => state.user,
  token: () => localStorage.getItem("token"),
  organizationId: (state) => (state.user ? state.user.organization : null),
  getTeamMembers: (state) => (state.teamMembers ? state.teamMembers : null),
  getAppSettingsData: (state) =>
    state.settingsData ? state.settingsData : null,
  getOrganizationCurrency: (state) =>
    state.settingsData ? state.settingsData.currency.toUpperCase() : null,
};

const mutations = {
  setItoken(state, data) {
    state.itoken = data;
  },

  setUser(state, data) {
    if (data.user != undefined) {
      state.user = data.user;
    } else {
      state.user = data;
    }

    state.isLoggedIn = true;
  },
  setSingleUser(state, data) {
    if (data.singleUser != undefined) {
      state.singleUser = data.user;
    } else {
      state.singleUser = data;
    }
  },

  emptyUser(state) {
    state.user = null;
    state.isLoggedIn = false;
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
  },

  // set the team members array
  setTeamMember(state, data) {
    state.teamMembers = data;
  },

  // get the app settings data
  setAppSettings(state, data) {
    state.settingsData = data;
  },
};

const actions = {
  async login({ commit }, payload) {
    try {
      const { data } = await Auth.login(payload);
      commit("setUser", data);
      localStorage.setItem("token", data.jwt);
      localStorage.setItem("userId", data.user.id);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async logout({ commit }) {
    commit("emptyUser");
  },

  async forgotPassword({ commit }, payload) {
    commit("emptyUser");

    try {
      const { data } = await Auth.forgotPassword(payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async checkInviteCode({ commit }, payload) {
    try {
      const { data } = await Auth.checkInviteCode(payload);
      commit("setItoken", payload);
      // save user token to browser
      sessionStorage.setItem("itoken", payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async register({ commit }, payload) {
    try {
      const { data } = await Auth.registerUser(payload);
      commit("setUser", data);
      // save user token to browser
      localStorage.setItem("token", data.jwt);
      localStorage.setItem("userId", data.user.id);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async registerCoWorkerUser({ commit }, payload) {
    try {
      const data = await Auth.registerCoWorkerUser(payload);
      commit("coworker", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchUserDetails({ commit }) {
    try {
      const { data } = await Auth.getUser();
      commit("setUser", data);
      return data;
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      return Promise.reject(error);
    }
  },

  async getUserDetails({ commit }, id) {
    try {
      const response = await Auth.getUserById(id);
      commit("setSingleUser", response.data);
      // console.log(JSON.stringify(response, null, 2));
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateUserDetails({ commit }, data) {
    try {
      const response = await Auth.upadateUserById(data.userId, data.payload);
      commit("setUser", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getTeamMembers({ commit }, id) {
    try {
      const response = await Auth.getTeamMembers(id);
      commit("setTeamMember", response.data.data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async settingUserApp({ commit }, organizationId) {
    try {
      const { data } = await Auth.getAppUserSettings(organizationId);
      commit("setAppSettings", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
