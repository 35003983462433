<template>
  <div class="overview-money-out-card">
    <span class="mtitle">Money Out</span>
    <span class="amount" ref="moneyOut">{{
      value && (value.total === 0 || value.total === "") ? "₦0.00" : "--"
    }}</span>

    <div
      class="empty-summary"
      v-if="
        !value ||
        ((value.bills === 0 || !value.bills) &&
          (value.expense === 0 || !value.expense))
      "
    >
      <img :src="require('@/assets/icons/empty-summary.svg')" />
    </div>
    <div class="bcover">
      <apexchart
        v-if="value && (value.bills || value.expense)"
        type="donut"
        :width="donutWidth"
        :options="chartOptions"
        :series="[value.bills, value.expense]"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  data() {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#E3AA1C", "#16BE98"],
        labels: ["Bills/Invoice", "Expenses"],

        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -5,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  color: "#596A73",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 700,
                  //offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "This Month",
                  color: "#8F96A1",
                  fontSize: "14px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
              legend: {
                show: true,
                position: "right",
                horizontalAlign: "center",
                fontSize: "14px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                labels: {
                  colors: "#8F96A1",
                },
                markers: {
                  width: 15,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: "#fff",
                  fillColors: ["#E3AA1C", "#16BE98"],
                  radius: 12,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0,
                },
                itemMargin: {
                  horizontal: 1,
                },
                formatter: function (seriesName, opts) {
                  return (
                    seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                  );
                },
              },
            },
          },
        },
      },
      screenWidth: 0,
    };
  },

  methods: {
    setValues(val) {
      const options = {
        useEasing: true,
        duration: 3,
        decimalPlaces: 2,
        formattingFn: (n) =>
          Intl.NumberFormat("en-NG", {
            currency: "NGN",
            style: "currency",
          }).format(n),
      };
      if (val.total) {
        new CountUp(this.$refs.moneyOut, val.total, options).start();
      }
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.setValues(val);
            }, 800);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    donutWidth() {
      if (this.screenWidth < 600) {
        return `330px`;
      } else if (this.screenWidth > 600 && this.screenWidth < 768) {
        return `60%`;
      } else if (this.screenWidth > 768 && this.screenWidth < 1200) {
        return `50%`;
      } else if (this.screenWidth > 1200 && this.screenWidth < 1300) {
        return `60%`;
      } else if (this.screenWidth > 1300 && this.screenWidth < 1400) {
        return `80%`;
      } else if (this.screenWidth > 1400 && this.screenWidth < 1600) {
        return `60%`;
      } else return `90%`;
    },
  },
};
</script>

<style lang="scss">
.overview-money-out-card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .empty-summary {
    height: 170px;
    width: 170px;
    border-radius: 50%;
    border: 30px solid #f6f3ee;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bcover {
    @media (max-width: 768px) {
      // margin: auto;
      transform: translateX(-30px);
    }
  }

  .mtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .amount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: #19283d;
  }
}
</style>
