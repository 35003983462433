<template>
  <div class="overview-balance">
    <div class="overview-balance__content">
      <span class="mtitle">Balance</span>
      <span class="amount" ref="balance">{{
        value && (value.total === 0 || value.total === "") ? "₦0.00" : "--"
      }}</span>
      <div class="content-row">
        <span class="entity">Banks</span>
        <span class="bal" ref="banks">{{
          value && value.banks === 0 ? "₦0.00" : "--"
        }}</span>
        <span class="action">
          <img :src="require('@/assets/balance-refresh.svg')" alt="" />
          <span class="content">refresh</span>
        </span>
      </div>
      <!-- <div class="content-row">
        <span class="entity">Hyphen</span>
        <span class="bal" ref="wallet">{{
          value && value.wallet === 0 ? "₦0.00" : "--"
        }}</span>
        <span class="action">
          <img :src="require('@/assets/balance-withdraw.svg')" alt="" />
          <span class="content">withdraw</span>
        </span>
        <span class="action">
          <img :src="require('@/assets/balance-topup.svg')" alt="" />
          <span class="content">topup</span>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  methods: {
    setValues(val) {
      const options = {
        useEasing: true,
        duration: 3,
        decimal: ".",
        decimalPlaces: 2,
        formattingFn: (n) =>
          Intl.NumberFormat("en-NG", {
            currency: "NGN",
            style: "currency",
          }).format(n),
      };
      if (val.total) {
        new CountUp(this.$refs.balance, val.total, options).start();
      }

      if (val.banks) {
        new CountUp(this.$refs.banks, val.banks, options).start();
      }

      // if (val.wallet) {
      //   new CountUp(this.$refs.wallet, val.wallet, options).start();
      // }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.setValues(val);
            }, 1500);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-balance {
  padding: 20px;
  border: 1px solid #f1dfd0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.0278628));
  border-radius: 4px;
  border-radius: 4px;

  @media (min-width: 768px) {
    padding: 30px 40px;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .mtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      color: rgba(25, 40, 61, 0.8);
    }

    .amount {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
      color: #19283d;
      min-width: 50px;
    }

    .content-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      line-height: 10px;

      font-family: "Inter";
      font-style: normal;

      .entity {
        font-weight: 700;
        font-size: 12px;
        color: #596a73;
      }

      .bal {
        font-weight: 400;
        font-size: 12px;
        color: #596a73;
        min-width: 10px;
      }

      .action {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        padding: 1px;

        .content {
          font-weight: 600;
          font-size: 12px;
          text-decoration-line: underline;
          color: #5b67ba;
          padding: 1px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
