export default async function ({ store, to, redirect }) {
  const tools =
    store.state.flagsmith.flags.find((flag) => flag.key === "tools")?.value ??
    true;

  const inbox =
    store.state.flagsmith.flags.find((flag) => flag.key === "inbox")?.value ??
    true;

  const forms =
    store.state.flagsmith.flags.find((flag) => flag.key === "forms")?.value ??
    true;

  const workflows =
    store.state.flagsmith.flags.find((flag) => flag.key === "workflows")
      ?.value ?? true;

  // console.log("workflows", workflows);
  var roleId = localStorage.getItem("roleId");

  if (!roleId || roleId === "null" || roleId === "undefined") {
    roleId = store?.state?.auth?.user?.role?.id + "";
  }

  localStorage.setItem("roleId", roleId);
  const hasWorkflowAccess = roleId === "1";

  if (!tools) {
    return redirect("/");
  } else if (!inbox && to.path.includes("/inbox")) {
    return redirect("/");
  } else if (!forms && to.path.includes("/form")) {
    return redirect("/");
  } else if (
    (!workflows && to.path.includes("/workflow")) ||
    (to.path.includes("/workflow") && !hasWorkflowAccess)
  ) {
    return redirect("/");
  }
}
