<template>
  <v-card
    elevation="0"
    tile
    max-height="100vh"
    min-height="100%"
    class="pa-0 ma-0"
  >
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="8"
          max-height="100%"
          class="primer d-flex justify-center"
        >
          <v-scroll-y-transition leave-absolute mode="out-in">
            <SignUp
              v-if="isSignup"
              class="ma-auto"
              @nextPage="switchDelimiter"
            />
            <Login v-else-if="isLogin" />
            <Recover-password v-else-if="isRecovery" />
            <reset-password v-else-if="isReset" />
          </v-scroll-y-transition>
        </v-col>
        <v-col
          max-height="100%"
          style="position: relative"
          cols="12"
          md="4"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <div
            class="d-flex flex-column align-center justify-center full-height"
            v-if="$route.name == 'Login'"
          >
            <div class="d-flex flex-column text-wrap">
              <h1>
                Bursary
                <br />
                Automation Portal
              </h1>

              <p class="pt-6 section__body" style="max-width: 306px">
                Onboard, approve, request and post all transactions. Get
                accurate records of accounts and expenses
              </p>
            </div>
          </div>
          <div
            class="d-flex flex-column align-center justify-center full-height"
            v-else
          >
            <div class="d-flex flex-column text-wrap">
              <h1>
                Bursary
                <br />
                Automation Portal
              </h1>

              <p class="pt-6 section__body" style="max-width: 306px">
                Onboard, approve, request and post all transactions. Get
                accurate records of accounts and expenses
              </p>
              <p class="pt-6 section__body" style="max-width: 306px">
                Start with an account in 5mins
              </p>

              <div class="pt-2 d-flex align-center" v-if="isSignup && stepOne">
                <v-icon class="">mdi-checkbox-blank-circle</v-icon>
                <v-icon color="grey lighten-2" class="px-1"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </div>
              <div class="pt-2 d-flex align-center" v-if="isSignup && !stepOne">
                <v-icon color="grey lighten-2" class=""
                  >mdi-checkbox-blank-circle</v-icon
                >
                <v-icon class="px-1">mdi-checkbox-blank-circle</v-icon>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Login from "../pages/auth/Login.vue";
import RecoverPassword from "../pages/auth/forgotPassword.vue";
import SignUp from "../pages/auth/SignUp.vue";
import ResetPassword from "../pages/auth/resetPassword.vue";

export default {
  name: "FormLayout",
  data() {
    return {
      stepOne: true,
    };
  },

  components: {
    Login,
    SignUp,
    RecoverPassword,
    ResetPassword,
  },

  computed: {
    isSignup() {
      return this.$route.name === "SignUp";
    },
    isLogin() {
      return this.$route.name === "Login";
    },
    isRecovery() {
      return this.$route.name === "forgot-password";
    },
    isReset() {
      return this.$route.name === "reset-password";
    },
  },
  methods: {
    switchDelimiter() {
      this.stepOne = false;
    },
  },
};
</script>

<style>
.full-height {
  min-height: 100%;
  /* max-height: 100vh; */
}
.section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
  color: #19283d;
}
.section__body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
