<template>
  <div class="overview-expected-revenue">
    <span class="mtitle">Expected Revenue</span>
    <span class="amount" ref="expected">{{
      value && (value.expected === 0 || value.expected === "") ? "₦0.00" : "--"
    }}</span>
    <span class="message"><span ref="customers">0</span> customers</span>
  </div>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  methods: {
    setValues(val) {
      const options = {
        useEasing: true,
        duration: 3,
        decimalPlaces: 2,
        formattingFn: (n) =>
          Intl.NumberFormat("en-NG", {
            currency: "NGN",
            style: "currency",
          }).format(n),
      };
      if (val.expected) {
        new CountUp(this.$refs.expected, val.expected, options).start();
      }

      if (val.customers) {
        new CountUp(this.$refs.customers, val.customers).start();
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.setValues(val);
            }, 3500);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-expected-revenue {
  padding: 20px;
  border: 1px solid #f1dfd0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.0278628));
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    padding: 30px 40px;
  }

  .mtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .amount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: #19283d;
  }

  .message {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f96a1;
  }
}
</style>
