//data object for the currency filter
export default {
  data() {
    return {
      currencyDefault: {
        symbol: "₦", //currency symbol for naira
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
      },
      currencyNoSymbol: {
        symbol: "", //no currency symbol

        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
    };
  },
};
