<template>
  <div>
    <!-- sideNav for $vuetify.breakpoint.mdAndDown -->

    <div v-if="$vuetify.breakpoint.mdAndDown">
      <template>
        <v-app-bar color="primary" dark v-if="!noNavRoutes.includes($route.name)">
          <v-app-bar-nav-icon @click="drawerM = true" class="pl-2"></v-app-bar-nav-icon>

          <v-toolbar-title></v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer v-if="!noNavRoutes.includes($route.name)" v-model="drawerM" absolute temporary>
          <template v-slot:prepend>
            <div>
              <img class="logo" :src="require('@/assets/aau-logo.png')" />
            </div>
          </template>
          <v-list nav flat class="py-2 pl-10">
            <v-list-item-group v-model="model" active-class="primary--text text--accent-4 link-text">
              <v-list-item nav link to="/insight" class="mx-auto" v-if="version1">
                <v-list-item-icon class="mr-4">
                  <img :src="require('@/assets/icons/insights.svg')" class="navIcon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navTxt">Insights</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template>
                <v-list-item nav link to="/" class="mx-auto">
                  <v-list-item-icon class="mr-4">
                    <img :src="require('@/assets/icons/row-vertical.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">Overview</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-group :value="false" v-if="features.tools" appendIcon>
                <template v-slot:activator>
                  <v-list-item class="mx-auto" style="padding-left: 0px">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/ruler&pen.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content class="">
                      <v-list-item-title class="text-subtitle-1">Tools</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- Customization of the append-icon slot -->
                <template v-slot:appendIcon>
                  <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
                </template>
                <template v-if="features.tools">
                  <v-list-item v-if="features.inbox" nav link to="/inbox" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/Vector.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Invoices</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.forms" nav link to="/form" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/forms.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Forms</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.workflows && $hasAccessTo.workflows" nav link to="/workflow" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/workflows.svg')" class="navIcon #7F919B" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Workflows</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.approvals" nav link to="/approvals" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/transactions.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Approvals</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>

              <v-list-group :value="false" v-if="features.payment" appendIcon>
                <template v-slot:activator>
                  <v-list-item class="mx-auto" style="padding-left: 0px">
                    <v-list-item-icon class="mr-4 d-flex justify-content-start">
                      <img :src="require('@/assets/money-4.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1">Money</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- Customization of the append-icon slot -->
                <template v-slot:appendIcon>
                  <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
                </template>
                <template v-if="features.payment">
                  <v-list-item v-if="features.payables" nav link to="/payable" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/payables.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Payables</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.receivables" nav link to="/receivables" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/receivables.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Receivables</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.transactions" nav link to="/transactions" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/transactions.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Payments</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>

              <v-list-group :value="false" v-if="features.intelligence" appendIcon>
                <template v-slot:activator>
                  <v-list-item class="mx-auto" style="padding-left: 0px">
                    <v-list-item-icon class="mr-3 d-flex justify-content-start">
                      <img :src="require('@/assets/money-4.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1">Counterparty</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- Customization of the append-icon slot -->
                <template v-slot:appendIcon>
                  <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
                </template>
                <template v-if="features.intelligence">
                  <v-list-item v-if="features.contacts" nav link to="/contacts" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/contacts.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Contacts</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="features.contacts" nav link to="/teams" class="mx-3">
                    <v-list-item-icon class="mr-4">
                      <img :src="require('@/assets/icons/contacts.svg')" class="navIcon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">Teams</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>


              <v-list-item nav link key="Integration" to="/integration" class="mx-auto" v-if="version1">
                <v-list-item-icon class="mr-4">
                  <img :src="require('@/assets/icons/puzzle_integration.svg')" class="navIcon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navTxt">Integrations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider class="mx-md-10" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
          <div class="pt-2">
            <v-list class="pl-10" nav flat>
              <v-list-item-group v-model="model" active-class="primary--text text--accent-4 link-text">
                <v-list-item nav link to="/settings" class="mx-auto">
                  <v-list-item-icon class="mr-1">
                    <img :src="require('@/assets/pbot_icons/settings.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">
                      Settings
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout" class="mx-auto">
                  <v-list-item-icon class="mr-4">
                    <img :src="require('@/assets/icons/Path.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">Logout</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <template v-slot:append absolute v-if="$vuetify.breakpoint.mdAndDown">
            <div class="pa-14">
              <v-avatar class="mx-auto" color="primary" size="60">
                <span class="white--text text-h5">MA</span>
              </v-avatar>
            </div>
          </template>
        </v-navigation-drawer>
      </template>
    </div>

    <!-- sideNav for $vuetify.breakpoint.mdAndUp -->

    <v-navigation-drawer v-if="!noNavRoutes.includes($route.name)" app fixed v-model="drawer" class="px-0">
      <template v-slot:prepend>
        <div>
          <img class="logo" :src="require('@/assets/aau-logo.png')" />
        </div>
      </template>
      <v-skeleton-loader width="100%"
        type="list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item"
        :loading="showMenuLoader">
        <v-list flat class="pt-2 pb-2 pl-6 navi_list">
          <v-list-item-group v-model="model" color="primary">
            <template>
              <v-list-item v-if="$hasAccessTo.overview" nav link to="/" class="mx-auto">
                <v-list-item-icon class="mr-4">
                  <img :src="require('@/assets/icons/row-vertical.svg')" class="navIcon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navTxt">Overview</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mr-md-6 ml-md-3" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
            </template>
            <!-- first batch of links from items1 -->
            <v-list-group :value="false" v-if="features.tools" appendIcon>
              <template v-slot:activator>
                <v-list-item class="mx-auto" style="padding-left: 0px">
                  <v-list-item-icon class="mr-4">
                    <img :src="require('@/assets/ruler&pen.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content class="">
                    <v-list-item-title class="navTitle">Tools</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Customization of the append-icon slot -->
              <template v-slot:appendIcon>
                <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
              </template>
              <template>
                <v-list-item nav link v-for="item in toolsItems" :key="item.name" :to="item.link" class="mx-6 py-0">
                  <v-list-item-icon class="mr-4">
                    <img :src="require(`@/assets/icons/${item.icon}`)" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="navTxt">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
            <v-divider class="mr-md-6 ml-md-3" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

            <!-- second batch of links frpm Items2 -->
            <v-list-group :value="false" v-if="features.payment" appendIcon>
              <template v-slot:activator>
                <v-list-item class="mx-auto" style="padding-left: 0px">
                  <v-list-item-icon class="mr-4 d-flex justify-content-start">
                    <img :src="require('@/assets/money-4.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="navTitle">Money</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Customization of the append-icon slot -->
              <template v-slot:appendIcon>
                <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
              </template>
              <template v-if="features.payment">
                <v-list-item nav link v-for="item in paymentItems" :key="item.name" :to="item.link" class="mx-6 py-0">
                  <v-list-item-icon class="mr-4">
                    <img :src="require(`@/assets/icons/${item.icon}`)" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="navTxt">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
            <v-divider class="mr-md-6 ml-md-3" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
            <!-- 3rd batch of  links -->
            <v-list-group :value="false" v-if="features.intelligence" appendIcon>
              <template v-slot:activator>
                <v-list-item class="mx-auto" style="padding-left: 0px">
                  <v-list-item-icon class="mr-3 d-flex justify-content-start">
                    <img :src="require('@/assets/money-4.svg')" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="navTitle">Counterparty</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Customization of the append-icon slot -->
              <template v-slot:appendIcon>
                <v-icon class="custom-append-icon">mdi-chevron-down</v-icon>
              </template>
              <template v-if="features.intelligence">
                <v-list-item nav link v-for="item in intelligenceItems" :key="item.name" :to="item.link"
                  class="mx-6 py-0">
                  <v-list-item-icon class="mr-4">
                    <img :src="require(`@/assets/icons/${item.icon}`)" class="navIcon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="navTxt">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
            <!-- <v-divider class="mr-md-6 ml-md-3" v-if="$vuetify.breakpoint.mdAndUp"></v-divider> -->
            <!-- 4TH batch of  links -->
            <!-- <v-list-item
            nav
            link
            v-for="item in items4"
            :key="item.name"
            :to="item.link"
            class="mx-auto"
          >
            <v-list-item-icon class="mr-4">
              <img
                :src="require(`@/assets/icons/${item.icon}`)"
                class="navIcon"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navTxt">{{
                item.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          </v-list-item-group>
        </v-list>
      </v-skeleton-loader>

      <template v-slot:append absolute>
        <div class="mx-md-10 mt-4">
          <p class="OrgTitle__text mb-0">Org ID</p>
          <p class="OrganValue__id mb-0">&#64;{{ getOrganHypnID }}</p>
        </div>
        <div class="initials" v-if="$vuetify.breakpoint.mdAndUp">
          <v-menu open-on-hover offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar class="mx-auto" color="primary" size="60" v-bind="attrs" v-on="on">
                <span class="white--text text-h5">{{ getUserInitials }}</span>
              </v-avatar>
            </template>
            <log-out-btn />
          </v-menu>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import logOutBtn from "@/includes/btns/logOutDropdown.vue";
import { mapGetters } from "vuex";
export default {
  components: { logOutBtn },
  data() {
    return {
      model: 1,
      version1: false,
      drawer: null,
      drawerM: false,
      showMenuLoader: true,
      noNavRoutes: [
        "Login",
        "SignUp",
        "OnBoarding",
        // "Create-form",
        "Render-form",
        "forgot-password",
        "reset-password",
        "organizationList",
        "Approval",
        "pendingActivation",
        "WorkflowSDK",
        "WorkflowSDKDemo",
        "Register",
        "Organization",
        "NewVendor",
        "transactionReceipt",
      ],

      items1: [
        {
          icon: "Vector.svg",
          name: "Invoices",
          link: "/inbox",
          flag: "inbox",
        },
        {
          icon: "forms.svg",
          name: "Forms",
          link: "/form",
          flag: "forms",
        },
        {
          icon: "workflows.svg",
          name: "Workflows",
          link: "/workflow",
          flag: "workflows",
        },
        {
          icon: "transactions.svg",
          name: "Approvals",
          link: "/approvals",
          flag: "approvals",
        },
      ],
      items2: [
        {
          icon: "payables.svg",
          name: "Payables",
          link: "/payable",
          flag: "payables",
        },
        {
          icon: "receivables.svg",
          name: "Receivables",
          link: "/receivables",
          flag: "receivables",
        },
        {
          icon: "transactions.svg",
          name: "Payments",
          link: "/transactions",
          flag: "transactions",
        },
      ],
      items3: [
        {
          icon: "contacts.svg",
          name: "Contacts",
          link: "/contacts",
          flag: "contacts",
        },
        {
          icon: "contacts.svg",
          name: "Teams",
          link: "/teams",
          flag: "contacts",
        },
      ],
      // items4: [
      //   {
      //     icon: "Path.svg",
      //     name: "Help",
      //     link: "#",
      //   },
      // ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.showMenuLoader = false;
    }, 2000);
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },

  computed: {
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
    }),

    getOrganHypnID() {
      return this.organizationToken?.data.hypn_id;
    },

    getUserInitials() {
      if (this.$store.state.auth.user !== null) {
        const first_name =
          (this.$store.state.auth.user.first_name &&
            this.$store.state.auth.user.first_name.charAt(0)) ||
          "";
        const last_name =
          (this.$store.state.auth.user.last_name &&
            this.$store.state.auth.user.last_name.charAt(0)) ||
          "";
        return (
          first_name + "." + last_name ||
          (this.$store.state.auth.user.username &&
            this.$store.state.auth.user.username.charAt(0)) ||
          ""
        ).toUpperCase();
      }
      return "";
    },

    features() {
      const tools = this.$flags.find((flag) => flag.key === "tools")?.value;
      const intelligence = this.$flags.find(
        (flag) => flag.key === "intelligence"
      )?.value;
      const payment = this.$flags.find((flag) => flag.key === "payment")?.value;
      const inbox = this.$flags.find((flag) => flag.key === "inbox")?.value;
      const forms = this.$flags.find((flag) => flag.key === "forms")?.value;
      const workflows = this.$flags.find(
        (flag) => flag.key === "workflows"
      )?.value;
      const contacts = this.$flags.find(
        (flag) => flag.key === "contacts"
      )?.value;

      const payables = this.$flags.find(
        (flag) => flag.key === "payables"
      )?.value;

      const receivables = this.$flags.find(
        (flag) => flag.key === "receivables"
      )?.value;

      const approvals = this.$flags.find(
        (flag) => flag.key === "approvals"
      )?.value;

      const transactions = this.$flags.find(
        (flag) => flag.key === "transactions"
      )?.value;

      return {
        tools,
        intelligence,
        payment,
        inbox,
        forms,
        workflows,
        contacts,
        payables,
        receivables,
        transactions,
        approvals,
      };
    },

    toolsItems() {
      // reutrn all items with flags that are true and if item.name === workflows then check if (user && user.role === 1)
      return this.items1
        .filter((item) => this.features[item.flag])
        .filter((item) => {
          if (item.name === "Workflows") {
            return this.user && this.user.role.id === 1;
          }
          return true;
        });
    },

    paymentItems() {
      return this.items2.filter((item) => this.features[item.flag]);
    },

    intelligenceItems() {
      return this.items3.filter((item) => this.features[item.flag]);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 8px;
  justify-content: center;
}

.logo {
  height: 90px;
  width: auto;
  display: block;
  object-fit: contain;
  margin: 15px auto;

  // tab up
  @media (min-width: 768px) {
    height: 90px;
    width: auto;
    margin: 15px 35px;
  }
}

.initials {
  padding: 20px;
  // padding-left: 30px;
}

.navIcon {
  width: 14px;
  fill: currentColor;
  color: #7f919b;
}

.navTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #7f919b;
  transition: all 0.1s ease;
}

.navTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #7f919b;
  transition: all 0.1s ease;
}

/* .navlink {
  color: var(--v-primary-base);
}
.active .v-list-item-group .v-list-item--active {
  text-decoration: underline solid lightseagreen 2px;
} */

.navi_list .v-list-item-group .v-list-item--active .navTxt {
  color: var(--v-primary-base);
  font-weight: 600;
  font-size: 15px;
}

.OrgTitle__text {
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
  color: #19283d;
}

.OrganValue__id {
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  color: #949fb5;
}

.v-icon.v-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 18px !important;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  align-self: center !important;
  margin: 0 !important;
  min-width: 48px !important;
  justify-content: flex-start !important;
}

.custom-append-icon {
  margin-right: 16px;
}
</style>
