<template>
  <div class="overview-money-in-card">
    <span class="mtitle">Money In</span>
    <span class="amount" ref="moneyIn">{{
      value && (value.total === 0 || value.total === "") ? "₦0.00" : "--"
    }}</span>
    <div class="chart">
      <apexchart
        v-if="canShow"
        type="area"
        width="100%"
        height="200"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  data() {
    return {
      canShow: false,
      series: [
        {
          name: "Money In",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "area",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#038C8C",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: () => {
              return "ss";
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            formatter: (val) => {
              return Intl.NumberFormat("en-NG", {
                currency: "NGN",
                style: "currency",
              }).format(val);
            },
          },
        },
        fill: {
          type: "gradient",
          opacity: 1,
          gradient: {
            shade: "light",
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0.4,
          },
        },
        grid: {
          show: false,
        },
        markers: {
          size: 4,
          hover: {
            size: 0,
            sizeOffset: 6,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
      },
    };
  },
  methods: {
    setValues(val) {
      const options = {
        useEasing: true,
        duration: 3,
        decimalPlaces: 2,
        formattingFn: (n) =>
          Intl.NumberFormat("en-NG", {
            currency: "NGN",
            style: "currency",
          }).format(n),
      };
      if (val.total) {
        new CountUp(this.$refs.moneyIn, val.total, options).start();
      }

      if (val.seven_days) {
        this.series = [
          {
            data: val.seven_days.map((x) => x.total),
            name: "Money In",
          },
        ];

        this.options = {
          ...this.options,
          xaxis: {
            categories: val.seven_days.map((x) => x.day),
          },
        };

        this.canShow = true;
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.setValues(val);
            }, 100);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.apexcharts-menu-icon {
  display: none;
}

.overview-money-in-card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .mtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .amount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: #19283d;
  }
}
</style>
