import overview from "../../api/overview";

const state = {
  data: null,
};
const getters = {
  overviewData: (state) => state.data,
};
const mutations = {
  SET_OVERVIEW_DATA(state, data) {
    state.data = data;
  },
};
const actions = {
  async getData({ commit }, hyphenId) {
    try {
      const response = await overview.getData(hyphenId);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response.data.data.data, null, 2));
      }
      commit("SET_OVERVIEW_DATA", response.data.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
