import Formlayout from "@/layouts/Form-layout.vue";
import forgotPassword from "@/pages/auth/new-forgotpassword.vue";

import Login from "@/pages/auth/new-login.vue";
import Register from "@/pages/auth/new-sign-up.vue";
import Organization from "@/pages/auth/new-organization.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    name: "Form-layout",
    component: Formlayout,
  },
  {
    path: "/error",
    component: () => import("@/views/error-page.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { middleware: ["guest"] },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: forgotPassword,
    meta: { middleware: ["guest"] },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: Formlayout,
    // meta: { middleware: ["guest", "auth"] },
  },
  {
    path: "/pending-activation",
    name: "pendingActivation",
    components: {
      default: () => import("@/views/pending-activation.vue"),
    },
    meta: { middleware: ["auth"] },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: Formlayout,
    // meta: { middleware: ["guest"] },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    // meta: { middleware: ["guest"] },
  },

  {
    path: "/register/organization",
    name: "Organization",
    component: Organization,
  },
];

export default routes;
