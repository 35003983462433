import api from "./Api";

export default {
  //get all payments for an organization
  getAllTransactions(organization_hypnID, start, limit) {
    return api().get(
      `transactions/payments/${organization_hypnID}?_start=${start}&_limit=${limit}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  // get a single payment for an organization
  getTransaction(organization_hypnID, transactionId) {
    return api().get(
      `transactions/payments/${organization_hypnID}/${transactionId}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },


  // post a bank transaction
  postBankTransactions(organisationId, payload) {
    return api().post(`transactions/post/${organisationId}`, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get all banks and bank codes
  getAllBanks() {
    return api().get("banks/all", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // add a bank
  addBank(organisationId, payload) {
    return api().post(`accounts/${organisationId}/add`, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get transaction summary
  getTransactionsSummary(organisationId) {
    return api().get(`transactions/summary/${organisationId}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get transaction receipt...
  getPaymentReceipt(organisationId, receiptId) {
    return api().get(`payments/receipt/${organisationId}/${receiptId}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  // get transaction recipients...
  getTransactionRecipients(organization_hypnID) {
    return api().get(`transactions/beneficiary/${organization_hypnID}?page=0`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // confirm bank account holder
  getBankDetailsConfirmation(nip_code, accountnumber) {
    return api().get(`accounts/verify-bank/${nip_code}/${accountnumber}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // create transaction recipients...
  CreateTransactionRecipients(organization_hypnID, payload) {
    return api().post(`transactions/beneficiary/${organization_hypnID}`, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // send money...
  sendTransactionMoney(organization_hypnID, payload) {
    return api().post(`transactions/send-money/${organization_hypnID}`, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get the approvals for transactions on form entries
  getTransactionApprovals(form_entry_id) {
    return api().get(`transactions/approvals/${form_entry_id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  }
};
