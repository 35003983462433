export default async function ({ store, redirect, to }) {
  const intelligence =
    store.state.flagsmith.flags.find((flag) => flag.key === "intelligence")
      ?.value ?? true;

  const contacts =
    store.state.flagsmith.flags.find((flag) => flag.key === "contacts")
      ?.value ?? true;

  const payaybles =
    store.state.flagsmith.flags.find((flag) => flag.key === "payaybles")
      ?.value ?? true;

  const receivables =
    store.state.flagsmith.flags.find((flag) => flag.key === "receivables")
      ?.value ?? true;

  if (!intelligence) {
    return redirect("/");
  } else if (!contacts && to.path.includes("/contacts")) {
    return redirect("/");
  } else if (!payaybles && to.path.includes("/payayble")) {
    return redirect("/");
  } else if (!receivables && to.path.includes("/receivables")) {
    return redirect("/");
  }
}
