import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      orgId: "auth/organizationId",
      orgHypnId: "organizations/getOrganizationHypnID",
      actionsMeta: "workflow/allActions",
      $flags: "flagsmith/flags",
      $orgDetails: "organizations/organObj",
      $summary: "transactions/getTranSummary",
      $appSettings: "auth/getAppSettingsData",
      appCurrency: "auth/getOrganizationCurrency",
      walletID: "overview/wallet_id",
      $orgHypnId: "organizations/getOrganizationHypnID"
    }),

    $hasAccessTo() {
      return {
        workflows: this.user ? this.user.role.id === 1 : false,
        overview: this.$orgDetails ? this.$orgDetails?.token?.kyc : false,
      };
    },
  },


};
