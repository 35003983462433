import contact from "../../api/contact";

const state = {
  vendors: [],
  customers: [],
  singleVendor: null,
  singleCustomer: null,
  singleCustomerInvoices: null,
  singleVendorInvoices: null,
  sendInvoiceResponse: null,
  customerPaymentInvoices: null,
  vendorPaymentInvoices: null,
  customerBankDetails: null,
  inviteVendorResponse: null,
  confirmToken: null,
  customerBalance: null,
  portalInvite: null,
  cancelInvoice: null,
};
const getters = {
  getAllVendors(state) {
    if (state.vendors.length != 0) {
      const approvedVendors = state.vendors.filter(
        (vendor) => vendor.approved == true
      );
      // console.log(JSON.stringify(approvedVendors, null, 2));
      const sortedApprovedVendors = approvedVendors.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      return sortedApprovedVendors;
    }
  },
  // get all customers and arrange them by created date
  getAllCustomers(state) {
    if (state.customers.length != 0) {
      const sortedCustomers = state.customers.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return sortedCustomers;
    }
  },

  PendingVendors(state) {
    if (state.vendors.length != 0) {
      const filteredVendor = state.vendors.filter(
        (vendor) => !vendor.approved == true
      );
      const sortedPendingVendors = filteredVendor.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      return sortedPendingVendors;
    }
  },

  numberOfContacts(state) {
    return state.vendors.length + state.customers.length;
  },
  getVendor(state) {
    if (state.singleVendor != null) {
      return state.singleVendor;
    }
  },
  getCustomer(state) {
    if (state.singleCustomer != null) {
      return state.singleCustomer;
    }
  },
  getCustomerHypnId(state) {
    if (state.singleCustomer != null) {
      return state.singleCustomer.data.hypn_id;
    }
  },

  getVendorInvoices(state) {
    if (state.singleVendorInvoices != null) {
      return state.singleVendorInvoices;
    }
  },

  // getVendorInvoicesById(state) {
  //     if (state.singleVendorInvoices != null) {
  //         return state.singleVendorInvoices;
  //     }
  // },
  getCustomerInvoicesById(state) {
    return state.singleCustomerInvoices;
  },
  getCustomerCurrency(state) {
    return state.singleCustomerInvoices &&
      state.singleCustomerInvoices.length > 0
      ? state.singleCustomerInvoices[0].currency
      : "NGN";
  },

  //map through customers and get the company name != null

  getCustomersCompanyName(state) {
    if (state.customers != null) {
      const customerArray = state.customers.map((customer) => {
        if (customer.company != null) {
          return {
            id: customer.hypn_id,
            company: customer.company.company_name,
            entity_type: "customer",
          };
        }
      });

      //filter the companyNameArray to remove null values
      const filteredCompanyArray = customerArray.filter(
        (company) => company != null
      );

      return filteredCompanyArray;
    }
  },

  // map to get an array of just vendors and hypn_id
  getVendorsCompanyName(state) {
    if (state.vendors != null) {
      const vendorArray = state.vendors.map((vendor) => {
        if (vendor.hypn_id != null && vendor.approved) {
          return {
            id: vendor.hypn_id,
            company: vendor.vendorname,
            entity_type: "vendor",
          };
        }
      });

      return vendorArray.filter(Boolean);
    }
  },

  getVendorAndCustomerCompanyName(state, getters) {
    if (state.vendors != null && state.customers != null) {
      const array = getters.getCustomersCompanyName.concat(
        getters.getVendorsCompanyName
      );

      return array.filter(Boolean);
    }
  },

  getCustomerTags(state) {
    if (state.customers != null) {
      // filter the array to get all non empty tags, map through to get a new array of arrays  of tag, flatten the aray to a single array of object using the reduce array method and then map to replace all null values of the prop "tag_name".
      // NB: this was neccessary bcos of some data where initially created with empty and null values and also the tags array was recently added to the customer response data....

      let custTagsArray = state.customers
        .filter((item) => item.tag.length > 0)
        .map((item) => item.tag)
        .reduce((acc, cur) => {
          return acc.concat(cur);
        }, [])
        .map((obj) => {
          if (obj.tag_name === null) {
            obj.tag_name = obj.label;
          }
          return obj;
        });

      return custTagsArray;
    }
  },

  getSendInvoiceResponse(state) {
    return state.sendInvoiceResponse;
  },

  // get customer payment invoices
  getContactPaymentInvoices(state) {
    if (state.customerPaymentInvoices != null) {
      return state.customerPaymentInvoices.data;
    }
  },
  // get vendor payment invoices
  getVendorPaymentInvoices(state) {
    if (state.vendorPaymentInvoices != null) {
      return state.vendorPaymentInvoices;
    }
  },

  // // get the data array from the contact payment invoices
  // getContactPaymentInvoicesDat(state, getters) {
  //     if (getters.getContactPaymentInvoices != null) {
  //         return getters.getContactPaymentInvoices.data;
  //     }
  // },

  // get the total amount of the contact payment invoices
  getContactPaymentInvoicesTotal(state) {
    if (state.customerPaymentInvoices != null) {
      return state.customerPaymentInvoices.count;
    }
  },

  // get the customer bank details
  getCustomerBankDetails(state) {
    if (state.customerBankDetails != null) {
      return state.customerBankDetails.data;
    }
  },
  // get the customer outstanding balance
  getCustomerOutstandingBalance(state) {
    if (state.customerBalance != null) {
      return state.customerBalance;
    }
  },
};
const mutations = {
  setVendors(state, vendors) {
    state.vendors = vendors;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  SET_SINGLE_VENDOR(state, data) {
    state.singleVendor = data;
  },
  SET_SINGLE_CUSTOMER(state, data) {
    state.singleCustomer = data;
  },
  SET_SINGLE_CUSTOMER_INVOICES(state, data) {
    state.singleCustomerInvoices = data;
  },

  SET_SINGLE_VENDOR_INVOICES(state, data) {
    state.singleVendorInvoices = data;
  },
  SET_SEND_CUSTOMER_INVOICE(state, data) {
    state.sendInvoiceResponse = data;
  },
  SET_CUSTOMER_PAYMENT_INVOICES(state, data) {
    state.customerPaymentInvoices = data;
  },
  SET_VENDOR_PAYMENT_INVOICES(state, data) {
    state.vendorPaymentInvoices = data;
  },

  SET_CUSTOMER_BANK_DETAILS(state, data) {
    state.customerBankDetails = data;
  },
  SET_INVITE_VENDOR_RESPONSE(state, data) {
    state.inviteVendorResponse = data;
  },
  setConfirmToken(state, data) {
    state.confirmToken = data;
  },
  setCustomerBalance(state, data) {
    state.customerBalance = data;
  },
  setPortalInvite(state, data) {
    state.portalInvite = data;
  },
  setCancelCustomer(state, data) {
    state.cancelInvoice = data;
  },
};
const actions = {
  async fetchAllVendors({ commit, rootState }) {
    if (rootState.auth.user != null) {
      try {
        const response = await contact.getAllVendors(
          await rootState.auth.user.organization
        );
        commit("setVendors", response.data.data);
        //console.log(JSON.stringify(response.data.data));
        return response;
      } catch (error) {
        //console.log(JSON.stringify(error, null, 2));
        return Promise.reject(error);
      }
    }
  },
  async fetchAllCustomers({ commit, rootState }) {
    if (rootState.auth.user != null) {
      try {
        const response = await contact.getAllCustomers(
          await rootState.auth.user.organization
        );
        commit("setCustomers", response.data.data);
        if (process.env.NODE_ENV == "development") {
          console.log(JSON.stringify(response.data.data, null, 2));
        }
        return response;
      } catch (error) {
        //console.log(JSON.stringify(error, null, 2));
        return Promise.reject(error);
      }
    }
  },

  async getSingleVendor({ commit }, id) {
    try {
      const response = await contact.getVendorById(id);
      commit("SET_SINGLE_VENDOR", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleCustomer({ commit }, id) {
    try {
      const response = await contact.getCustomerById(id);
      commit("SET_SINGLE_CUSTOMER", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateVendorDetails({ commit }, data) {
    try {
      const response = await contact.updateVendorById(data.id, data.payload);

      commit("SET_SINGLE_VENDOR", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //vuex action to create a new customer for the organization
  async createNewCustomer({ commit, rootState }, payload) {
    try {
      const response = await contact.createCustomer(
        rootState.auth.user.organization,
        payload
      );
      commit("SET_SINGLE_CUSTOMER", response.data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateCustomerDetails({ commit }, data) {
    try {
      const response = await contact.updateCustomerById(data.id, data.payload);

      commit("SET_SINGLE_CUSTOMER", response.data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //vuex actions for getting a customer invoices
  async getCustomerInvoices({ commit, rootGetters }, customerId) {
    // console.log(rootGetters["auth/organizationId"]);
    try {
      const { data } = await contact.getCustomerInvoices(
        rootGetters["auth/organizationId"],
        customerId
      );
      commit("SET_SINGLE_CUSTOMER_INVOICES", data.data);
      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  //vuex actions for getting a vendor invoices
  async getVendorInvoices({ commit, rootGetters }, vendorId) {
    // console.log(rootGetters["auth/organizationId"]);
    try {
      const { data } = await contact.getVendorInvoices(
        rootGetters["auth/organizationId"],
        vendorId
      );
      commit("SET_SINGLE_VENDOR_INVOICES", data.data);
      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //vuex actions for getting all the invoices belonging to a vendor
  async getOutstandingBalance({ commit }, request) {
    try {
      const { data } = await contact.getCustomerBalance(
        request.orgHpynId,
        request.custHypnId
      );
      commit("setCustomerBalance", data.data);
      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //vuex actions for sending customer invoice
  async sendCustomerInvoice({ commit }, payload) {
    try {
      const { data } = await contact.sendCustomerInvoice(payload);
      commit("SET_SEND_CUSTOMER_INVOICE", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // vuex actions for getting contact payment invoices
  async getCustomerPaymentInvoices({ commit }, customerId) {
    try {
      const { data } = await contact.getCustomerInvoicePayments(customerId);
      commit("SET_CUSTOMER_PAYMENT_INVOICES", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // vuex actions for getting vendor payment invoices
  async getVendorPayment({ commit }, vendorId) {
    try {
      const { data } = await contact.getVendorPayments(vendorId);
      commit("SET_VENDOR_PAYMENT_INVOICES", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // vuex actions for getting customer bank details
  async getCustomerBankDetails({ commit }, { organizationId, customerId }) {
    try {
      const { data } = await contact.getCustomerBankDetails(
        organizationId,
        customerId
      );

      commit("SET_CUSTOMER_BANK_DETAILS", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // vuex actions for inviting a vendor
  async inviteAVendor({ commit }, request) {
    try {
      const { data } = await contact.inviteVendor(
        request.organizationId,
        request.payload
      );
      commit("SET_INVITE_VENDOR_RESPONSE", data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // confirm invite token
  async confirmToken({ commit }, token) {
    try {
      const { data } = await contact.confirmInviteVendorToken(token);
      commit("setConfirmToken", data.data);

      sessionStorage.setItem("invitetoken", data.data.session);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // vuex actions to send portal invite
  async sendPortalInvitation({ commit }, payload) {
    try {
      const { data } = await contact.sendPortalInvite(payload);
      commit("setPortalInvite", data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // vuex actions to cancel customer invoice
  async cancelCustomerInvoice({ commit }, payload) {
    try {
      const { data } = await contact.cancelInvoice(payload);
      commit("setCancelCustomer", data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
