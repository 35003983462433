<template>
  <div class="auth">
    <div class="auth__wrapper">
      <div class="header">
        <a href="/">
          <img
            :src="require('@/assets/aau-logo.png')"
            class="app-logo"
            alt="Hyphen"
          />
        </a>
      </div>
      <div class="content">
        <form @submit="submitForm" class="login-form">
          <span class="title">Forgot Your Password ?</span>
          <div>
            <v-text-field
              ref="email"
              color="primary"
              placeholder="Email"
              background-color="#ffffff"
              outlined
              class="ma-0"
              :disabled="isSending"
              type="email"
              hide-details="auto"
              v-model="formData.email"
              prepend-inner-icon="mdi-email-outline"
              :rules="rules.email"
            ></v-text-field>
            <div class="forgot">
              <a href="/login"> back to login </a>
            </div>
          </div>

          <v-btn
            type="submit"
            block
            color="primary"
            elevation="5"
            height="54px"
            width="88px"
            class="text-capitalize action__button"
            :loading="isSending"
          >
            Send Mail
          </v-btn>
        </form>
      </div>
    </div>
    <div class="auth__sidebar">
      <div class="cv">
        <h1>
          Bursary
          <br />
          Automation Portal
        </h1>

        <span class="msg">
          Onboard, approve, request and post all transactions. Get accurate
          records of accounts and expenses
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ForgotPassword",

  mounted() {},

  data() {
    return {
      showPass: false,
      formData: {
        email: "",
      },
      isSending: false,
      errorMsg: "",
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
    };
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async submitForm(e) {
      e.preventDefault();
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].validate(true);
      });

      if (this.canProceed) {
        try {
          this.isSending = true;
          await this.$store.dispatch("auth/forgotPassword", this.formData);

          this.showToast({
            sclass: "success",
            show: true,
            message: "Email Sent, Please Check Your Email",
            timeout: 5000,
          });

          setTimeout(() => {
            this.$router.replace("/login");
          }, 2000);
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        } finally {
          this.isSending = false;
        }
      }
    },
  },

  computed: {
    form() {
      return {
        email: this.formData.email,
      };
    },

    canProceed() {
      // loop through rules, if all pass user can try to login
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.formData[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  //   padding: 2rem 4rem;
  background: #f7f7f7;

  &__wrapper {
    width: 100vw;

    @media screen and (min-width: 768px) {
      width: calc(100vw - 30vw);
    }
  }

  .header {
    // height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;

    padding: 10px 20px;

    .app-logo {
      width: auto;
      height: 70px;
    }

    @media screen and (min-width: 1024px) {
      background-color: unset;
      width: unset;
    }
  }

  .content {
    width: 100vw;
    height: calc(100vh - 100px);
    max-width: unset;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1024px) {
      width: calc(100vw - 31vw);

      padding: 30px 150px;
    }

    .login-form {
      width: 100%;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 27px;

        letter-spacing: -0.73px;

        color: #596a73;
      }

      .forgot {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.278409px;
          color: #301f78;
          mix-blend-mode: normal;
          opacity: 0.5;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .no-account {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #7f919b;
        }

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #5b67ba;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  &__sidebar {
    display: none;
    width: 30vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;

    .cv {
      width: 90%;
      max-width: 400px;
    }

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      color: #19283d;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(25, 40, 61, 0.8);
    }

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
