import transactions from "../../api/transactions"; //import the api file for receivables

const state = {
  transactionsObject: null,
  allTransactions: [],
  bankTransaction: null,
  allBanks: null,
  newBank: null,
  transactionsSummary: null,
  generatedreceipt: null,
  payoutBeneficiaries: null,
  payoutNewBeneficiaries: null,
  sendMoneyResObj: null,
  confirmedAccount: null,
  formEntryApprovals: null,
}; //define the state

const getters = {
  getAllTransactions: (state) => {
    const sortedTransactions = [...state.allTransactions].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA; // Sort in descending order (most recent first)
    });
    return sortedTransactions;
  },

  getASingleTransaction: (state) => {
    return state.transactionsObject;

  },

  getAllBanks: (state) => {
    return state.allBanks;
  },

  getOnlyBankNames: (state) => {
    let onlyBankNames = state.allBanks.map((obj) => obj.bank);
    return onlyBankNames;
  },
  getTranSummary: () => {
    return state.transactionsSummary;
  },
  getGeneratedReceipt: () => {
    return state.generatedreceipt;
  },
  getPayoutBeneficiaries: () => {
    return state.payoutBeneficiaries;
  },
  getFormEntryApprovals: () => {
    return state.formEntryApprovals;
  },
};

const mutations = {
  //mutations for the state
  SET_ALL_TRANSACTIONS(state, data) {
    // state.transactionsObject = data;
    state.allTransactions = data.data;

    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(data.data, null, 2));
    }
  },

  SET_SINGLE_TRANSACTION(state, data) {
    state.transactionsObject = data;

    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(data, null, 2));
    }

  },

  SET_BANK_TRANSACTION(state, data) {
    state.bankTransaction = data.data;

    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(data, null, 2));
    }
  },

  SET_BANKS_ARRAY(state, data) {
    state.allBanks = data;
  },
  SET_NEW_BANK(state, data) {
    state.newBank = data;
  },
  SET_TRANS_SUMMARY(state, data) {
    state.transactionsSummary = data;
  },
  SET_PAYABLE_RECEIPT(state, data) {
    state.generatedreceipt = data;
  },
  SET_PAYOUT_RECIPIENTS(state, data) {
    state.payoutBeneficiaries = data;
  },
  SET_NEW_PAYOUT_RECIPIENTS(state, data) {
    state.payoutNewBeneficiaries = data;
  },
  SET_SEND_MONEY(state, data) {
    state.sendMoneyResObj = data;
  },
  SET_CONFIRMED_DETAILS(state, data) {
    state.confirmedAccount = data;
  },
  SET_FORM_ENTRY_APPROVALS(state, data) {
    state.formEntryApprovals = data.approvals;
  },
};

const actions = {
  //actions for the state
  async getOrgTransactions({ commit }, dataObject) {
    try {
      const response = await transactions.getAllTransactions(
        dataObject.organization_hypnID,
        dataObject.start,
        dataObject.limit
      );
      commit("SET_ALL_TRANSACTIONS", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get a single payment for an organization
  async getTransaction({ commit }, dataObject) {
    try {
      const {data} = await transactions.getTransaction(
        dataObject.organization_hypnID,
        dataObject.transactionId
      );
      commit("SET_SINGLE_TRANSACTION", data.data);
      return data;

    } catch (error) {
      return Promise.reject(error);
    }
  },

  // post a bank transaction

  async postABankTransactions({ commit }, dataObject) {
    try {
      const response = await transactions.postBankTransactions(
        dataObject.organizationId,
        dataObject.payload
      );
      commit("SET_BANK_TRANSACTION", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get all the banks names and code
  async getAllBankNamesAndCodes({ commit }) {
    try {
      const response = await transactions.getAllBanks();
      commit("SET_BANKS_ARRAY", response.data);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // add a bank Account

  async addABank({ commit }, dataObject) {
    try {
      const response = await transactions.addBank(
        dataObject.organizationId,
        dataObject.payload
      );
      commit("SET_NEW_BANK", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get the transaction summary for the auth organization using the organ id

  async transSummary({ commit }, organisationId) {
    try {
      const { data } = await transactions.getTransactionsSummary(
        organisationId
      );
      commit("SET_TRANS_SUMMARY", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get a transaction payable receipt using the orgId and the receiptId...

  async generatePayableReceipt({ commit }, req_data) {
    try {
      const { data } = await transactions.getPaymentReceipt(
        req_data.organisationId,
        req_data.receiptId
      );
      commit("SET_PAYABLE_RECEIPT", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // get payout Beneficiaries

  async getPayoutBeneficiaries({ commit }, organization_hypnID) {
    try {
      const { data } = await transactions.getTransactionRecipients(
        organization_hypnID
      );
      commit("SET_PAYOUT_RECIPIENTS", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CreatePayoutBeneficiaries({ commit }, req_data) {
    try {
      const { data } = await transactions.CreateTransactionRecipients(
        req_data.organization_hypnID,
        req_data.payload
      );
      commit("SET_NEW_PAYOUT_RECIPIENTS", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // post action for sending money
  async sendMoney({ commit }, req_data) {
    try {
      const { data } = await transactions.sendTransactionMoney(
        req_data.organization_hypnID,
        req_data.payload
      );
      commit("SET_SEND_MONEY", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // confirm account details
  async confirmAccount({ commit }, req_query) {
    try {
      const { data } = await transactions.getBankDetailsConfirmation(
        req_query.nip_code,
        req_query.accountnumber
      );
      commit("SET_CONFIRMED_DETAILS", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get the transactions approval ona partiular form entries
  async getTransactionApprovals({ commit }, form_entry_id) {
    try {
      const { data } = await transactions.getTransactionApprovals(
        form_entry_id
      );
      commit("SET_FORM_ENTRY_APPROVALS", data.data);
      // console.log(JSON.stringify(data.data));
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
