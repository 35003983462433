import ui from "./ui";
import workflow from "./workflow";
import auth from "./auth";
import organizations from "./organizations";
import formBuilder from "./formBuilder";
import invoices from "./invoices";
import contacts from "./contacts";
import payables from "./payables";
import receivables from "./receivables";
import transactions from "./transactions";
import flagsmith from "./flagsmith";
import approvals from "./approvals";
import overview from "./overview";

export default {
  ui,
  workflow,
  auth,
  organizations,
  formBuilder,
  invoices,
  contacts,
  payables,
  receivables,
  transactions,
  flagsmith,
  approvals,
  overview,
};
