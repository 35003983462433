import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/form",
    name: "Form",
    components: {
      default: () => import("@/pages/forms/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/form/new-form",
    name: "Create-form",
    components: {
      default: () => import("@/pages/forms/createForm"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/edit-form/:id",
    name: "EditForm",
    props: true,
    components: {
      default: () => import("@/pages/forms/editForm.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/form/:id",
    name: "entries",

    props: true,
    components: {
      default: () => import("@/pages/forms/entries"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
];

export default routes;
