import { Promise } from "core-js";
import organization from "../../api/organization";
import Organization from "../../api/organization";

const state = {
  organization: null,
  fullOrganizationObject: null,
  inviteOrg: null,
  // invitedCoWorkers: [],
  organizationToken: null,
  cacDoc: null,
  idCard: null,
  bank: null,
  coWorkers: [],
  banks: [],
  bankAccounts: [],
};

const getters = {
  OrganToken(state) {
    // return state.organizationToken if not null
    if (state.organizationToken) {
      return state.organizationToken;
    }
  },
  // get organization hypnID from organToken
  getOrganizationHypnID(state) {
    if (state.organizationToken) {
      return state.organizationToken.data.hypn_id;
    }
  },

  // get organization id from organToken
  getOrganizationID(state) {
    if (state.organizationToken) {
      return state.organizationToken.data.organization;
    }
  },

  organObj(state) {
    return state.fullOrganizationObject;
  },
  getOrganizationLogoData(state) {
    if (state.fullOrganizationObject) {
      return state.fullOrganizationObject.logo;
    }
  },
  getOrganizationEmail(state) {
    //console.log(state.organizationToken.data);
    return state.organizationToken.hypn_email;
  },

  getOrganizationBank(state) {
    return state.bank;
  },

  getOrganizationCoWorkers(state) {
    return state.coWorkers;
  },

  getOrganizationBanks(state) {
    return state.banks;
  },
  getOrganizationBankAccounts(state) {
    return state.bankAccounts;
  },
  // getOrganizationBankAccountsMasked(state) {
  //     return state.bankAccounts.map(account => {
  //         if (account.account_number) {
  //             account.account_number = account.account_number.replace(/^.{6}/, '****');
  //         }
  //         return account;
  //     });
  // },

  // get a filtered array of account type bank for the side nav drawer
  getAccountObject(state) {
    return state.bankAccounts
      .filter((account) => account.type === "bank")
      .map((account) => {
        let bankName = "";
        if (typeof account.bank === "string") {
          bankName = account.bank;
        } else if (
          typeof account.bank === "object" &&
          account.bank !== null &&
          account.bank.bank_name !== null
        ) {
          bankName = account.bank.bank_name;
        }

        return {
          account: account.account,
          hypn_id: account.hypn_id,
          bank: bankName,
        };
      });
  },
  // get a filtered array of account type internal for the side nav drawer
  getAccountObjectForInternal(state) {
    return state.bankAccounts
      .filter((account) => account.type === "internal")
      .map((account) => {
        return {
          account: account.account,
          hypn_id: account.hypn_id,
          account_name: account.account_name,
        };
      });
  },
};

const mutations = {
  commitOrganization(state, organization) {
    state.organization = organization;
  },
  organizationToken(state, token) {
    state.organizationToken = token;
  },
  setFullOrganization(state, data) {
    state.fullOrganizationObject = data;
  },

  setInvite(state, data) {
    state.inviteOrg = data;
  },

  commitAddBank(state, bank) {
    state.bank = bank;
  },
  setOrganizationLogo(state, data) {
    state.fullOrganizationObject.logo = data;
  },
  // coWorkerState(state, coWorker) {
  //     state.invitedCoWorkers = coWorker;
  // },
  setCAC(state, cacDoc) {
    state.cacDoc = cacDoc;
  },
  setIdCard(state, idCard) {
    state.idCard = idCard;
  },

  setCoWorkers(state, coWorkers) {
    state.coWorkers = coWorkers;
  },

  setBanks(state, banks) {
    state.banks = banks;
  },
  setOrganBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts;
  },
};

const actions = {
  async createOrganization({ commit }, organization) {
    try {
      const response = await Organization.create(organization);
      commit("commitOrganization", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateOrganization({ commit, rootState }, data) {
    try {
      const response = await Organization.update(
        rootState.auth.user.organization,
        data
      );
      commit("commitOrganization", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async uploadCacDoc({ commit }, payload) {
    try {
      const response = await Organization.uploadOrganCac(payload);
      commit("setCAC", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async uploadIdCard({ commit }, payload) {
    try {
      const response = await organization.uploadIdCard(payload);
      commit("setIdCard", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async organizationLogoUpload({ commit }, payload) {
    try {
      const response = await organization.uploadOrganizationLogo(payload);
      commit("setOrganizationLogo", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getOrganizationToken({ commit, rootState }) {
    try {
      const response = await organization.getOrganizationToken(
        rootState.auth.user.organization
      );
      commit("organizationToken", response.data);
      if (process.env.NODE_ENV === "development") {
        // console.log(response.data);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getOrganizationById({ commit }, id) {
    try {
      const { data } = await organization.getOrganById(id);
      commit("setFullOrganization", data);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getInviteOrg({ commit }, id) {
    try {
      const data = await organization.getInviteOrg(id);
      commit("setInvite", data);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async inviteCoWorker({ rootState }, payload) {
    try {
      const response = await organization.inviteCoWorker(
        rootState.auth.user.organization,
        payload
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchCoWorkers({ commit, rootGetters }) {
    try {
      const { data } = await organization.getCoWorkers(
        rootGetters["auth/organizationId"]
      );

      commit("setCoWorkers", data.data);

      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async addBank({ commit, rootState }, bankCode) {
    try {
      const response = await organization.addBankAccount(
        rootState.auth.user.organization,
        bankCode
      );
      commit("commitAddBank", response.data);

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchBanks({ commit }) {
    try {
      const { data } = await organization.getBanks();

      commit("setBanks", data.data);

      return data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get an organization bank accounts
  async getOrgBankAccounts({ commit }, organizationId) {
    try {
      const { data } = await organization.getAllAccounts(organizationId);

      commit("setOrganBankAccounts", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchOrganizationSettings({ commit }, id) {
    try {
      const { data } = await organization.getOrganizationSettings(id);

      commit("setOrganSettings", data);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
