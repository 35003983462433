import receivables from "../../api/receivables"; //import the api file for receivables

const state = {
  receivables: null,
  allReceivables: null,
  receivablesBalance: null,
  customer: null,
}; //define the state

const getters = {
  //getters for the state
  getReceivables: (state) => state.receivables,
  getAllReceivables: (state) => {
    if (state.allReceivables) {
      return state.allReceivables.filter((receivable) => receivable !== null);
    } else return null;
  },
  getReceivablesBalance: (state) => state.receivablesBalance,
  // get the count of all receivables only if the state is not null
  getReceivablesCount: (state) =>
    state.allReceivables ? state.allReceivables.length : 0,

  // get all receivables withs status paid
  getAllPaidReceivables: (state) => {
    const receivables = state.allReceivables.map((receivable) => {
      if (receivable && receivable.status == "paid") {
        return receivable;
      } else return null;
    });

    // return receivables that is not null
    return receivables.filter((receivable) => receivable !== null);
  },
};

const mutations = {
  //mutations for the state
  SET_RECEIVABLE(state, data) {
    state.receivables = data;
    //console.log(JSON.stringify(data, null, 2));
  },
  SET_ALL_RECEIVABLES(state, data) {
    state.allReceivables = data;
    if (process.env.NODE_ENV === "development") {
      // console.log(JSON.stringify(data, null, 2));
    }
  },
  SET_RECEIVABLES_BALANCE(state, data) {
    state.receivablesBalance = data;
    if (process.env.NODE_ENV === "development") {
      // console.log(JSON.stringify(data, null, 2));
    }
  },
};

const actions = {
  //actions for the state
  async getAllReceivables({ commit, rootGetters }) {
    try {
      const response = await receivables.getAllReceivables(
        rootGetters["organizations/getOrganizationHypnID"]
      );
      commit("SET_ALL_RECEIVABLES", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //get a single receivable of an organisation using the organization_hypnID and receivable ID
  async getReceivable({ commit, rootGetters }, id) {
    // console.log(rootGetters["organizations/getOrganizationHypnID"]);
    try {
      const response = await receivables.getReceivable(
        rootGetters["organizations/getOrganizationHypnID"],
        id
      );
      commit("SET_RECEIVABLE", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //get receievables balance for an organization
  async getReceivablesBalance({ commit, rootGetters }) {
    try {
      const response = await receivables.getReceivablesBalance(
        rootGetters["organizations/getOrganizationHypnID"]
      );
      commit("SET_RECEIVABLES_BALANCE", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
